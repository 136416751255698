import React from 'react';
import * as Styled from './arrowListContentListStyles';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { FormTypes, sectionList } from '../../constants/queryFragments';
import loadable from '@loadable/component';
import { Col, Row } from 'react-bootstrap';
import { Body } from '../../styles';
import isExternalUrl, { addTrailingSlash, isExternalUrlHref } from '../../utils';

const ArrowListContentList = ({ sectionData }) => {
  const [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    setLoaded(true);
  }, []);
  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.HEADING_2]: (node, children) => (
        <Styled.HeaderH2>{children}</Styled.HeaderH2>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <Styled.HeaderH3>{children}</Styled.HeaderH3>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <Styled.HeadingH4>{children}</Styled.HeadingH4>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.ParaText>{children}</Styled.ParaText>
      ),
      [BLOCKS.UL_LIST]: (node, children) => (
        <Styled.UnorderedList>{children}</Styled.UnorderedList>
      ),
      [BLOCKS.LIST_ITEM]: (node, children) => (
        <Styled.ListItem>{children}</Styled.ListItem>
      ),
      [INLINES.HYPERLINK]: (node, children) => (
        <Styled.LinkStyle
          href={
            isExternalUrlHref(node?.data?.uri)
              ? node?.data?.uri
              :addTrailingSlash( 
                process.env.GATSBY_DOMAIN_URL + node?.data?.uri
              )
          }
        >
          {children}
        </Styled.LinkStyle>
      ),
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  };
  return !loaded ? (
    <Styled.Container>
      <Body>
        {sectionData?.header?.trim() && (
          <Styled.HeaderH2>{sectionData?.header}</Styled.HeaderH2>
        )}
        {sectionData?.subText?.trim() && (
          <Styled.HeaderH3>{sectionData?.subText}</Styled.HeaderH3>
        )}
        {sectionData?.contentDetails &&
          documentToReactComponents(
            JSON.parse(sectionData?.contentDetails?.raw),
            optionsMainStyle
          )}
        {sectionData?.listDetails &&
          documentToReactComponents(
            JSON.parse(sectionData?.listDetails?.raw),
            optionsMainStyle
          )}
        {sectionData && (
          <Col>
            <Row>
              {sectionData?.list &&
                sectionData?.list?.map((section, index) => {
                  if (
                    section?.type &&
                    sectionList?.includes(section?.type) &&
                    !FormTypes?.includes(section?.type)
                  ) {
                    const SectionComponent = loadable(() =>
                      import(
                        `../../components/${section?.type}/${section?.type}`
                      )
                    );
                    return (
                      <Col key={index} xs={12} lg={true}>
                        <SectionComponent key={index} sectionData={section} />
                      </Col>
                    );
                  }
                })}
            </Row>
          </Col>
        )}
      </Body>
    </Styled.Container>
  ) : (
    <Styled.Container>
      <Body>
        {sectionData?.header?.trim() && (
          <Styled.HeaderH2>{sectionData?.header}</Styled.HeaderH2>
        )}
        {sectionData?.subText?.trim() && (
          <Styled.HeaderH3>{sectionData?.subText}</Styled.HeaderH3>
        )}
        {sectionData?.contentDetails &&
          documentToReactComponents(
            JSON.parse(sectionData?.contentDetails?.raw),
            optionsMainStyle
          )}
        {sectionData?.listDetails &&
          documentToReactComponents(
            JSON.parse(sectionData?.listDetails?.raw),
            optionsMainStyle
          )}
        {sectionData && (
          <Col>
            <Row>
              {sectionData?.list &&
                sectionData?.list?.map((section, index) => {
                  if (
                    section?.type &&
                    sectionList?.includes(section?.type)
                  ) {
                    const SectionComponent = loadable(() =>
                      import(
                        `../../components/${section?.type}/${section?.type}`
                      )
                    );
                    return (
                      <Col key={index} xs={12} lg={true}>
                        <SectionComponent key={index} sectionData={section} />
                      </Col>
                    );
                  }
                })}
            </Row>
          </Col>
        )}
      </Body>
    </Styled.Container>
  );
};

export default ArrowListContentList;
